/* global document,window,navigator,jQuery,IS24 */

import "./style.css";

var reportingConfig = {
	homepage: {
		evt_ga_label: "homescreen",
		pag_pagetitle: "homepage"
	},
	result: {
		evt_ga_label: "results",
		pag_pagetitle: "finden///result"
	},
	expose: {
		evt_ga_label: "expose",
		pag_pagetitle: "finden///expose"
	}
};

// try about 3 seconds until ETS script is loaded.
var reportView = function (reportData, count) {
	var MAX = 30;

	if (count === undefined) {
		count = 1;
	}

	if (typeof IS24 === "undefined" && count < MAX) {
		return setTimeout(reportView, 100, reportData, count + 1);
	} else {
		if (count >= MAX) {
			return false;
		} else {
			// noinspection JSUnresolvedVariable
			IS24.TEALIUM.tracking.report(reportData);
		}
	}
};

var reportClick = function (reportData) {
	// noinspection JSUnresolvedVariable
	setTimeout(IS24.TEALIUM.tracking.report, 250, reportData);
};

var getQueryVariable = function (variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	var pair, i;

	for (i = 0; i < vars.length; i++) {
		pair = vars[i].split("=");

		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}
};

var extend = function (where) {
	Array.prototype.slice.call(arguments, 1).forEach(function (source) {
		var key;

		for (key in source) {
			if (source.hasOwnProperty(key)) {
				where[key] = source[key];
			}
		}
	});

	return where;
};

var $ = function (selector, element) {
	var el = element || document.documentElement;

	return el.querySelector(selector);
};


var classReg = function (c) {
	return new RegExp("(^|\\s+)" + c + "(\\s+|$)");
};

var storage = (function () {
	var sid = new Date().toString();
	var result = false;

	try {
		window.localStorage.setItem(sid, sid);
		result = (window.localStorage.getItem(sid) === sid);
		window.localStorage.removeItem(sid);

		return result && window.localStorage;
	} catch (e) { /* continue regardless of error */ }

})();

var setStorageView = function () {
	var sb;

	if (storage) {
		sb = JSON.parse(storage.getItem("smartbanner"));
		if (sb) {
			if (sb.viewCount >= 6) {
				setStorage(0, 7);
			} else {
				setStorage(sb.viewCount + 1, 0);
			}
		} else {
			setStorage(1, 0);
		}
	}
};

var getStorageViewCount = function () {
	var sb;

	if (storage) {
		sb = JSON.parse(storage.getItem("smartbanner"));
		if (sb) {
			return sb.viewCount;
		} else {
			return 0;
		}
	}
};

var setStorage = function (viewCount, days) {
	if (storage) {
		try {
			window.localStorage.setItem("smartbanner", JSON.stringify({
				viewCount: viewCount,
				nextView: Date.now() + 86400000 * days
			}));
		} catch (e) { /* continue regardless of error */ }
	}
};

var mixins = {
	ios: {
		appMeta: "apple-itunes-app",
		appId: "344176018",
		iconRels: ["apple-touch-icon-precomposed", "apple-touch-icon"],
		getStoreLink: function () {
			var resportingLinks = {
				homepage: "https://app.adjust.com/sj4orp",
				result: "https://app.adjust.com/g31lqb",
				expose: "https://app.adjust.com/6j1mx7",
				inka: "",
				default: "https://app.adjust.com/o4bukh"
			};

			return resportingLinks[this.options.page];
		}
	},
	android: {
		appMeta: "google-play-app",
		appId: "de.is24.android",
		iconRels: ["android-touch-icon", "apple-touch-icon-precomposed", "apple-touch-icon"],
		getStoreLink: function () {
			var resportingLinks = {
				homepage: "https://app.adjust.com/kshx00",
				result: "https://app.adjust.com/m4cs07",
				expose: "https://app.adjust.com/2hk1t0",
				inka: "",
				default: "https://app.adjust.com/xoogjs"
			};

			return resportingLinks[this.options.page];
		}
	}
};

var SmartBanner = function (options) {
	this.options = extend({}, {
		daysHidden: 7,
		daysReminder: 30,
		appStoreLanguage: "de",
		force: false
	}, options || {});

	if (this.options.force) {
		this.type = this.options.force;
	} else {
		this.type = getMobileOperatingSystem();
	}

	extend(this, mixins[this.type]);

	this.create();
	this.show();
};

var initializeSmartBanner = function (options) {
	var $ = jQuery.noConflict();
	var iOSApp = $("<meta>").attr("name", "apple-itunes-app").attr("content", "app-id=344176018");
	var androidApp = $("<meta>").attr("name", "google-play-app").attr("content", "de.is24.android");

	$("head").append(iOSApp).append(androidApp);

	setStorageView();
	new SmartBanner(options);
};

var addClass, removeClass;

function smartbannerTemplate({title, inStore, button, link}) {
	return `
	<div class='smartbanner__container'>
		<a href='javascript:void(0);' data-follow='true' class='smartbanner__close'>&times;</a>
		<span class='smartbanner__icon'></span>
		<div class='smartbanner__info'>
			<div class='smartbanner__title'>${title}
				<span class='smartbanner__instore'>${inStore}</span>
			</div>
			<div class='smartbanner__stars'>
				<i class='fa fa-star star-color-orange' aria-hidden='true'></i>
				<i class='fa fa-star star-color-orange' aria-hidden='true'></i>
				<i class='fa fa-star star-color-orange' aria-hidden='true'></i>
				<i class='fa fa-star star-color-orange' aria-hidden='true'></i>
				<i class='fa fa-star-o star-color-orange' aria-hidden='true'></i>
			</div>
		</div>
		<a href='${link}' data-follow='true' class='smartbanner-button' target='_blank'>
			<span class='smartbanner-button__text'>${button}</span>
		</a>
	</div>
	`;
}

export function getMobileOperatingSystem() {
	// noinspection JSUnresolvedVariable
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	if (/android/i.test(userAgent)) {
		return "android";
	}
	// noinspection JSUnresolvedVariable
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "ios";
	}

	return null;
}

if (!String.prototype.trim) {
	String.prototype.trim = function (s) {
		return s.replace(/(^\s*|\s*$)/g, "");
	};
}
addClass = function (el, c) {
	el.className = (el.className + " " + c).trim();
};
removeClass = function (el, c) {
	el.className = el.className.replace(classReg(c), " ").trim();
};


SmartBanner.prototype = {

	constructor: SmartBanner,

	create: function () {
		const link = this.getStoreLink();
		const inStore = this.options.info.de[this.type]; // + " - " + this.options.store[this.type];
		const sb = document.createElement("div");

		addClass(sb, "smartbanner");
		addClass(sb, "smartbanner_" + this.type);
		sb.innerHTML = smartbannerTemplate({
			title: this.options.title,
			inStore,
			link,
			button: this.options.button.de
		});

		document.body.insertBefore(sb, document.body.firstChild);
		$(".smartbanner-button", sb).addEventListener("click", this.install.bind(this), false);
		$(".smartbanner__close", sb).addEventListener("click", this.close.bind(this), false);
	},

	hide: function () {
		removeClass(document.documentElement, "smartbanner_show");
	},

	show: function () {
		addClass(document.documentElement, "smartbanner_show");
	},

	close: function () {
		this.hide();
		setStorage(0, this.options.daysHidden);
	},

	install: function () {
		this.hide();
		reportClick({
			evt_ga_category: "cxp",
			evt_ga_action: "smartbanner_clicked",
			evt_ga_label: reportingConfig[this.options.page].evt_ga_label,
			pag_pagetitle: reportingConfig[this.options.page].pag_pagetitle,
			ga_cd_cxp_smartbannershown: getStorageViewCount()
		});
		setStorage(0, this.options.daysReminder);
	}

};

window.SmartBanner = SmartBanner;

document.addEventListener("DOMContentLoaded", function () {
	var os = getMobileOperatingSystem();
	var forceMobile = window.location.search.indexOf("forceMobile") > -1;
	var nextView;
	var page = document.getElementById("smartbanner-script").getAttribute("data-page");

	if ((os || forceMobile) && storage) {
		if (storage.getItem("smartbanner")) {
			nextView = JSON.parse(storage.getItem("smartbanner")).nextView;
		}
		if (!nextView || Date.now() > nextView) {
			initializeSmartBanner({
				page: page,
				title: "ImmoScout24",
				button: {
					de: "Zur App",
					en: ""
				},
				info: {
					de: {
						ios: "funktioniert am<br>besten in der App",
						android: "funktioniert am<br>besten in der App"
					},
					en: {
						ios: "funktioniert am besten in der App",
						android: "funktioniert am besten in der App"
					}
				},
				force: getQueryVariable("forceMobile")
			});
			reportView({
				evt_ga_category: "cxp",
				evt_ga_action: "smartbanner_shown",
				evt_ga_label: reportingConfig[page].evt_ga_label,
				pag_pagetitle: reportingConfig[page].pag_pagetitle
			});
		}
	}
});
